import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "recepce10/recepce10-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "recepce10/recepce10-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "recepce10/recepce10-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "recepce10/recepce10-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "recepce10/recepce10-5.jpg" }) { ...fluidImage }
  }
`

const Recepce10Page = ({ data }) => (
  <Layout title="Recepce 10">
    <HorizontalImage marginTop borderBottom image={data.photo4.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo1.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Recepce Drtinova 10, Praha 5, 2015</Heading>
      <Text>
        Jedná se o hlavní recepci areálu Tiskárny Libertas a ostatních budov, které slouží ke komerčním pronájmům. Stávající recepce byla pro tyto potřeby malá a nevyhovující. Bylo nutné ji rozšířit o bezpečnostní kamery, informační systém, úschovu listové a balíkové pošty. Samotná recepce proto byla přemístěna z chodby do přilehlé místnosti. Vybouráním nového otvoru vzniklo komunikační okno mezi recepcí a chodbou. Stávající koridor byl očistěn od  sníženého podhledu a sadrokartonových předstěn. Do této čisté bílé chodby byl umístěn portál obložený  cortenem. Tento portál, umístěný mezi dvěma pilastry, je záměrně zešikmen směrem k výtahu pro zjednodušení navigace v prostoru. Za portálem je pod stejným úhlem umístěn černý panel s informačním sytémem. Díky zešikmení je vidět již od vstupních dvěří. K inspiraci návrhu recepčního pultu posloužily kovové litery, historicky používané při knihtisku. Byly vyrobeny z probarvené černé MDF desky. Prostor pod schodištěm je zavřen výsuvným panelem sloužícím jako schránky na poštu. Stávající kulaté pilastry byly podpořeny nasvětlenýmí nikami v SDK podhledu; atypicky bylo řešeno i osvětlení recepce boxem z plexiskla.
      </Text>
    </VerticalText>
    <HorizontalImage marginTop borderBottom borderTop image={data.photo2.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo5.childImageSharp.fluid} />
    <VerticalImage image={data.photo3.childImageSharp.fluid} />
  </Layout>
)

export default Recepce10Page
